.tab_group {
  border: 1px var(--black) solid;
  background-color: var(--white);
  border-radius: 0.5rem;
  margin: 4rem 0 7rem 0;
  position: sticky;
  top: 60px;
  gap: 1rem;
  height: 2.5rem;
  width: 99%;
  margin-inline: auto;
  padding: 0.2rem 0.5rem;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  overflow-x: auto;
}
.tab_group .tab {
  border: none;
  border-radius: 5rem;
  font-size: 1.1rem;
  background-color: transparent;
  min-width: 12rem;
  height: 100%;
  transition: border 50ms ease;
  border-top: 0px var(--black) solid;
  border-left: 0px var(--black) solid;
  border-right: 0px var(--black) solid;
  border-bottom: 0px var(--black) solid;
}
.tab_group .tab.active {
  border-top: 1px var(--black) solid;
  border-left: 2px var(--black) solid;
  border-right: 1px var(--black) solid;
  border-bottom: 3px var(--black) solid;
}
.tab_group .tab:active {
  border-top: 3px var(--black) solid;
  border-left: 1px var(--black) solid;
  border-right: 2px var(--black) solid;
  border-bottom: 1px var(--black) solid;
}

.dark-theme .tab_group {
  border: 1px var(--white) solid;
  background-color: var(--black);
}
.dark-theme .tab_group .tab {
  background-color: transparent;
  color: var(--white);
  border-top: 0px var(--white) solid;
  border-left: 0px var(--white) solid;
  border-right: 0px var(--white) solid;
  border-bottom: 0px var(--white) solid;
}
.dark-theme .tab_group .tab.active {
  border-top: 1px var(--white) solid;
  border-left: 2px var(--white) solid;
  border-right: 1px var(--white) solid;
  border-bottom: 3px var(--white) solid;
}
.dark-theme .tab_group .tab:active {
  border-top: 3px var(--white) solid;
  border-left: 1px var(--white) solid;
  border-right: 2px var(--white) solid;
  border-bottom: 1px var(--white) solid;
}

.tab_group_2 {
  border: 1px var(--black) solid;
  background-color: var(--white);
  border-radius: 0.5rem;
  margin: 4rem 0 7rem 0;
  position: sticky;
  top: 60px;
  gap: 1rem;
  height: 2.5rem;
  width: 99%;
  margin-inline: auto;
  padding: 0.2rem 0.5rem;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  overflow-x: auto;
}
.tab_group_2 .tab {
  border: none;
  border-radius: 5rem;
  font-size: 1.1rem;
  background-color: transparent;
  min-width: 12rem;
  height: 100%;
  transition: border 50ms ease;
  border-top: 0px var(--black) solid;
  border-left: 0px var(--black) solid;
  border-right: 0px var(--black) solid;
  border-bottom: 0px var(--black) solid;
}
.tab_group_2 .tab.active {
  border-top: 1px var(--black) solid;
  border-left: 2px var(--black) solid;
  border-right: 1px var(--black) solid;
  border-bottom: 3px var(--black) solid;
}
.tab_group_2 .tab:active {
  border-top: 3px var(--black) solid;
  border-left: 1px var(--black) solid;
  border-right: 2px var(--black) solid;
  border-bottom: 1px var(--black) solid;
}

.dark-theme .tab_group {
  border: 1px var(--white) solid;
  background-color: var(--black);
}
.dark-theme .tab_group .tab {
  background-color: transparent;
  color: var(--white);
  border-top: 0px var(--white) solid;
  border-left: 0px var(--white) solid;
  border-right: 0px var(--white) solid;
  border-bottom: 0px var(--white) solid;
}
.dark-theme .tab_group .tab.active {
  border-top: 1px var(--white) solid;
  border-left: 2px var(--white) solid;
  border-right: 1px var(--white) solid;
  border-bottom: 3px var(--white) solid;
}
.dark-theme .tab_group .tab:active {
  border-top: 3px var(--white) solid;
  border-left: 1px var(--white) solid;
  border-right: 2px var(--white) solid;
  border-bottom: 1px var(--white) solid;
}/*# sourceMappingURL=tabs.css.map */
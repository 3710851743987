.dark-theme {
  background-color: var(--black);
  color: white;
  /* Handle */
  /* Track */
  /* Handle */
}
.dark-theme .top_bar {
  border-bottom: 0.5px solid var(--dark-grey);
  background-color: var(--black);
}
.dark-theme .top_bar .icon_button {
  color: var(--white);
}
.dark-theme .top_bar .break {
  background-color: var(--dark-grey);
}
.dark-theme .sidebar {
  background-color: var(--black);
  border-right: 0.5px solid var(--dark-grey);
}
.dark-theme .sidebar a {
  border-bottom: 0.5px solid var(--dark-grey);
}
.dark-theme .sidebar a ion-icon,
.dark-theme .sidebar a i {
  color: var(--white);
}
.dark-theme .sidebar a button {
  background-color: var(--black);
  color: var(--white);
}
.dark-theme footer {
  background-color: var(--black);
  border-top: var(--white) 1px solid;
  color: rgb(189, 189, 189);
}
.dark-theme ::-webkit-scrollbar-thumb {
  background: #ffffff;
}
.dark-theme .links a,
.dark-theme .links button {
  border: 1px var(--white) solid;
  color: var(--white);
}
.dark-theme .links a:hover,
.dark-theme .links button:hover {
  box-shadow: 0px 3px 6px 0px rgba(255, 255, 255, 0.1), 0px 1px 3px 0px rgba(255, 255, 255, 0.08);
}
.dark-theme .the_intro .info {
  background-color: var(--black);
  border: var(--white) 2px solid;
}
.dark-theme ::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
.dark-theme ::-webkit-scrollbar-track {
  background: var(--black);
}
.dark-theme ::-webkit-scrollbar-thumb {
  background: var(--white);
}
.dark-theme .project_block {
  border-top: 2px var(--white) dashed;
}
.dark-theme .project_block .projects .project {
  border: var(--white) solid 2px;
}
.dark-theme .project_block .projects .project .img_box a button {
  background-color: var(--black);
  border: 1.5px solid var(--white);
  color: var(--white);
}
.dark-theme .project_block .projects .project .text_box {
  border: var(--white) solid 2px;
  background-color: rgba(0, 0, 0, 0.4);
}
.dark-theme .contact_block {
  border-top: 2px var(--white) dashed;
}
.dark-theme .contact_block .contacts .contact {
  color: var(--white);
  border: solid 1px var(--white);
}
.dark-theme .contact_block .contacts .contact:hover {
  box-shadow: 0px 3px 6px 0px rgba(255, 255, 255, 0.9), 0px 1px 3px 0px rgba(255, 255, 255, 0.92);
}
.dark-theme .the_intro .intro + :nth-child(3) {
  border-bottom: var(--white) dashed 6px;
}
.dark-theme .the_intro .info {
  background-color: var(--black);
  border: var(--white) 2px solid;
}
.dark-theme .new_tag {
  border-top: 1px var(--white) solid;
  border-left: 2px var(--white) solid;
  border-right: 1px var(--white) solid;
  border-bottom: 2px var(--white) solid;
  color: var(--white);
}
.dark-theme .new_tag:active {
  border-top: 2px var(--white) solid;
  border-left: 1px var(--white) solid;
  border-right: 2px var(--white) solid;
  border-bottom: 1px var(--white) solid;
}
.dark-theme .links_with_box .card {
  border: var(--white) dashed 2px;
}/*# sourceMappingURL=dark_theme.css.map */
@import url("https://fonts.googleapis.com/css2?family=Montserrat&family=Open+Sans&family=Roboto&family=Rubik+Mono+One&family=Space+Mono&family=Ubuntu&display=swap");
/*
font-family: 'Montserrat', sans-serif;
font-family: 'Open Sans', sans-serif;
font-family: 'Roboto', sans-serif;
font-family: 'Rubik Mono One', sans-serif;
font-family: 'Space Mono', monospace;
font-family: 'Ubuntu', sans-serif;
*/
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.4/font/bootstrap-icons.css");
* {
  padding: 0px;
  margin: 0px;
}

:root {
  --black: hsl(0, 0%, 13%);
  --white: hsl(0, 0%, 96%);
  --black-v1: #121212;
  --white-v2: #f5f5f5;
  --light-grey: rgb(226, 226, 226);
  --dark-grey: hsl(0, 0%, 24%);
  --accent: rgb(47, 197, 92);
  --dark-accent: rgb(54, 161, 86);
  background-color: var(--white);
  color: var(--black);
}

#root {
  width: 100%;
  min-height: 100vh;
}

.top_bar {
  top: 0px;
  position: sticky;
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid var(--light-grey);
  height: 50px;
  width: 100%;
  background-color: var(--white);
  z-index: 5;
}
.top_bar .icon_button {
  width: 100px;
  margin: auto 0px;
  text-align: center;
  color: var(--black);
  background-color: transparent;
  border: none;
  min-width: 60px;
  cursor: pointer;
}
.top_bar .icon_button i {
  font-size: 32px;
}
.top_bar .icon_button ion-icon {
  font-size: 28px;
}
.top_bar .icon_button img {
  height: 28px;
}
.top_bar .break {
  width: 1px;
  height: 80%;
  margin: auto 10px;
  border-radius: 1rem;
  background-color: var(--light-grey);
}
.top_bar .icon_right_group {
  height: 100%;
  margin-left: auto;
  display: flex;
  flex-direction: row;
}
.top_bar .icon_right_group .icon_button {
  height: 100%;
  line-height: 60px;
  width: 60px;
  min-width: 50px;
}

.sidebar {
  width: 400px;
  height: calc(100% - 50px);
  padding-top: 1px;
  overflow: hidden;
  background-color: var(--white);
  position: fixed;
  top: 50.5px;
  border-right: 1px solid var(--light-grey);
  z-index: 4;
}
.sidebar a {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid var(--light-grey);
  text-decoration: none;
}
.sidebar a ion-icon,
.sidebar a i {
  font-size: 24px;
  color: var(--black);
  margin: auto 20px;
}
.sidebar a button {
  width: 100%;
  padding: 4%;
  background-color: var(--white);
  color: var(--black);
  border: none;
  font-family: "Space Mono", monospace;
  font-size: 18px;
  cursor: pointer;
}
.sidebar.open {
  transform: translateX(0%);
  transition: transform 600ms ease-in-out;
}
.sidebar.closed {
  transform: translateX(-100%);
  transition: transform 600ms ease-in-out;
}

.sidebarBox {
  width: 100%;
  height: calc(100vh - 50.5px);
  position: absolute;
  top: 50.5px;
}
.sidebarBox.openBox {
  transform: translateX(0%);
}
.sidebarBox.closedBox {
  transform: translateX(-100%);
}

footer {
  margin-top: 60px;
  width: 100%;
  height: 100px;
  background-color: var(--white);
  border-top: var(--black) 1px solid;
  font-family: "Space Mono", monospace;
  color: rgb(77, 77, 77);
  font-size: 18px;
  display: flex;
  flex-direction: row;
}
footer p {
  margin: auto;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: none;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--black);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Rubik Mono One", sans-serif;
  text-align: center;
}

h1 {
  font-size: 60px;
  margin-top: 60px;
  margin-bottom: 0px;
}

h2 {
  font-size: 40px;
  margin-top: 60px;
  margin-bottom: 0px;
}

p,
button,
a {
  font-family: "Space Mono", monospace;
  font-size: 20px;
}

p {
  margin: 20px 10%;
  text-align: center;
}

.links {
  margin-top: 20px;
  width: -moz-min-content;
  width: min-content;
  margin-inline: auto;
}
.links a,
.links button {
  width: 330px;
  margin: 10px 20px;
  border: 1px var(--black) solid;
  padding: 10px;
  color: var(--black);
  text-decoration: none;
  border-radius: 8px;
  transition: box-shadow 0.4s;
  display: flex;
  flex-direction: row;
  background-color: transparent;
  text-align: center;
  align-items: center;
  justify-content: center;
}
.links a:hover,
.links button:hover {
  transition: box-shadow 0.4s;
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.1), 0px 1px 3px 0px rgba(0, 0, 0, 0.08);
}
.links a div,
.links button div {
  margin: auto;
  flex-direction: row;
  display: flex;
}

@media (max-width: 600px) {
  .sidebar {
    width: 100%;
  }
  h1 {
    font-size: 40px;
    margin-top: 60px;
    margin-bottom: 0px;
  }
  h2 {
    font-size: 26px;
    margin-top: 60px;
    margin-bottom: 0px;
  }
  .links {
    margin-top: 20px;
    width: 90%;
    margin-inline: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    max-width: 330px;
  }
  .links a {
    width: 100%;
    margin: 10px auto;
    padding: 5%;
  }
  footer {
    margin-top: 60px;
    width: 100%;
    height: 100px;
    font-size: 16px;
    display: flex;
    flex-direction: column;
  }
  footer p {
    margin: auto;
    font-size: 16px;
  }
}
.page_size {
  width: 100%;
  min-height: calc(100vh - 276px);
}

.new_tag {
  border-top: 1px var(--black) solid;
  border-left: 2px var(--black) solid;
  border-right: 1px var(--black) solid;
  border-bottom: 2px var(--black) solid;
  transition: border 50ms ease;
  color: var(--black);
  font-family: "Rubik Mono One", sans-serif;
  font-size: 14px;
  border-radius: 5rem;
  padding: 0.2rem 1rem 0rem 1rem;
  margin: auto 0rem auto 1.5ch;
  display: flex;
}
.new_tag div {
  margin: auto;
}
.new_tag:active {
  border-top: 2px var(--black) solid;
  border-left: 1px var(--black) solid;
  border-right: 2px var(--black) solid;
  border-bottom: 1px var(--black) solid;
}

.links_with_box {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0 1rem;
  gap: 1rem;
  box-sizing: border-box;
  max-width: 100%;
}
.links_with_box .links {
  width: 45%;
  min-width: 300px;
  flex: 1 1 auto;
}
.links_with_box .links button {
  margin: 10px auto;
  width: 100%;
}
.links_with_box .card {
  box-sizing: border-box;
  border: var(--black) dashed 2px;
  aspect-ratio: 8/4;
  margin: 0 auto;
  width: 45%;
  min-width: 300px;
  flex: 1 1 auto;
  margin: 0;
  padding: 1rem 0;
}
.links_with_box .card * {
  margin: 0;
}
.links_with_box .card .links {
  margin: 0 auto;
}
.links_with_box .card .links a {
  width: auto;
  margin: 0 auto;
}
.links_with_box .card h3 {
  font-size: 2.5rem;
}
.links_with_box .card p {
  margin: 1rem 1rem;
}/*# sourceMappingURL=main.css.map */
.the_intro {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 80px;
  height: 100%;
}
.the_intro .intro {
  font-family: "Rubik Mono One", sans-serif;
  font-size: 110px;
  margin-left: 50px;
  margin-left: 3%;
  overflow-wrap: break-word;
  text-align: left;
  margin-top: 0px;
}
.the_intro .intro + :nth-child(2) {
  font-size: 34px;
  margin-left: 5%;
}
.the_intro .intro + :nth-child(3) {
  font-size: 65px;
  margin-left: 5%;
  overflow-wrap: break-word;
  border-bottom: var(--black) dashed 6px;
}
.the_intro .info {
  font-family: "Space Mono", monospace;
  font-size: 24px;
  margin-inline: 6%;
  margin-top: 100px;
  margin-bottom: 100px;
  padding: 6%;
  background-color: var(--white);
  border: var(--black) 2px solid;
}

.project_block {
  border-top: 2px var(--black) dashed;
  margin-top: 0px;
  width: 100%;
  font-family: "Space Mono", monospace;
}
.project_block h2 {
  margin-inline: auto;
  font-size: 40px;
  margin-bottom: 60px;
  font-family: "Rubik Mono One", sans-serif;
  text-align: center;
  margin-top: 100px;
}
.project_block p {
  margin-inline: 50px;
  margin-top: 0px;
  margin-bottom: 60px;
  font-size: 20px;
  text-align: center;
}
.project_block .projects {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 95%;
  gap: 20px;
  max-width: 100%;
  margin: 0px auto;
}
.project_block .projects .project {
  aspect-ratio: 1.95/1;
  width: 45%;
  margin: 40px auto;
  border: var(--black) solid 2px;
  border-radius: 20px;
  display: flex;
  min-width: 500px;
  max-width: 700px;
  overflow: hidden;
  position: relative;
}
.project_block .projects .project .img_box {
  position: relative;
  display: inline-block;
  border-radius: inherit;
  width: 65%;
  width: 580px;
  aspect-ratio: 2/3;
  border-radius: 1.5rem;
  transform: skew(-20deg);
  margin: 0.25rem 0 0.3rem -75px;
  overflow: hidden;
  transition: 400ms ease-in-out scale, filter;
  scale: 1;
  filter: blur(0px);
}
.project_block .projects .project .img_box:hover {
  transition: 400ms ease-in-out;
  scale: 1.08;
}
.project_block .projects .project .img_box:hover a {
  transition: 500ms ease-in-out;
  visibility: visible;
  scale: 0.92;
  opacity: 1;
  filter: blur(0px);
  margin-top: 30%;
}
.project_block .projects .project .img_box:hover a button {
  transition: 500ms ease-in-out;
  opacity: 1;
}
.project_block .projects .project .img_box:hover img {
  transition: 400ms ease-in-out;
  filter: blur(3px);
}
.project_block .projects .project .img_box img {
  position: relative;
  top: 0;
  left: 0;
  transform: skew(20deg);
  width: auto;
  min-width: 120%;
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: inherit;
}
.project_block .projects .project .img_box a {
  position: absolute;
  top: 0px;
  left: 0px;
  margin-left: 42.5%;
  margin-top: 60%;
  width: 30%;
  height: 10%;
  z-index: 2;
  visibility: none;
  opacity: 0;
  filter: blur(0px);
  transition: 500ms ease-in-out;
}
.project_block .projects .project .img_box a button {
  opacity: 0;
  transition: 500ms ease-in-out;
  transform: skew(20deg);
  filter: blur(0px);
  width: 100%;
  height: 100%;
  background-color: var(--white);
  font-family: "Space Mono", monospace;
  font-size: 18px;
  border: 1.5px solid var(--black);
  border-radius: 6px;
  cursor: pointer;
}
.project_block .projects .project .text_box {
  width: 30%;
  padding: 3%;
  line-height: 24px;
  margin-left: auto;
  font-size: 18px;
  text-align: center;
  text-overflow: ellipsis;
  overflow: hidden;
  min-width: 190px;
  overflow-y: auto;
  margin: 0.6rem;
  border-radius: 10px;
  border: var(--black) solid 2px;
  z-index: 1;
  background-color: rgba(255, 255, 255, 0.4);
}
.project_block .projects .project .text_box h3 {
  font-weight: normal;
  font-family: "Rubik Mono One", sans-serif;
  font-size: 24px;
  padding-bottom: 3%;
  text-align: center;
}

.contact_block {
  border-top: 2px var(--black) dashed;
  margin-top: 60px;
  margin-bottom: 100px;
  padding-top: 100px;
}
.contact_block h2 {
  margin-inline: 50px;
  font-size: 40px;
  padding-top: 0px;
  padding-bottom: 40px;
  font-family: "Rubik Mono One", sans-serif;
  text-align: center;
}
.contact_block p {
  margin-inline: 50px;
  font-size: 24px;
  margin-top: 0px;
  margin-bottom: 60px;
  font-size: 22px;
  text-align: center;
}
.contact_block .contacts {
  width: 90%;
  max-width: 90%;
  margin-inline: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 60px;
}
.contact_block .contacts .contact {
  width: auto;
  height: 60px;
  border-radius: 8px;
  transition: box-shadow 0.4s;
  display: flex;
  flex-direction: row;
  margin-inline: auto;
  color: var(--black);
  text-decoration: none;
  margin-block: 10px;
  border: solid 1px var(--black);
  overflow: hidden;
  min-width: 410px;
}
.contact_block .contacts .contact ion-icon {
  font-size: 40px;
  margin-block: auto;
  margin-inline: 20px;
  min-width: 40px;
}
.contact_block .contacts .contact p {
  margin-inline: 10px;
  padding-top: auto;
  padding-bottom: auto;
  font-family: "Space Mono", monospace;
  margin-block: auto;
  font-size: 20px;
  width: 260px;
}
.contact_block .contacts .contact p.sub_txt {
  color: rgb(165, 165, 165);
  display: none;
}
.contact_block .contacts .contact:hover {
  transition: box-shadow 0.4s;
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.1), 0px 1px 3px 0px rgba(0, 0, 0, 0.08);
}

@media (max-width: 600px) {
  .the_intro {
    padding-top: 60px;
  }
  .the_intro .intro {
    font-family: "Rubik Mono One", sans-serif;
    font-size: 85px;
  }
  .the_intro .intro + :nth-child(2) {
    font-size: 20px;
  }
  .the_intro .intro + :nth-child(3) {
    font-size: 32px;
  }
  .the_intro .info {
    font-family: "Space Mono", monospace;
    font-size: 20px;
    margin-inline: 6%;
    margin-top: 40px;
    margin-bottom: 60px;
    padding: 5%;
  }
  .project_block h2 {
    margin-inline: auto;
    font-size: 34px;
    margin-bottom: 20px;
    text-align: center;
    margin-top: 40px;
  }
  .project_block p {
    margin-inline: 4%;
    margin-bottom: 0px;
  }
  .project_block .projects {
    display: flex;
    flex-direction: column;
    width: 95%;
    margin: 0px auto;
  }
  .project_block .projects .project {
    aspect-ratio: 1/1.4;
    width: 80%;
    margin: 40px auto;
    border: var(--black) solid 2px;
    border-radius: 20px;
    display: flex;
    overflow: hidden;
    position: relative;
    flex-direction: column;
    min-width: 0px;
  }
  .project_block .projects .project .img_box {
    position: absolute;
    display: block;
    border-radius: inherit;
    width: 100%;
    aspect-ratio: 2/2.2;
    border-radius: 1.5rem;
    transform: skew(-20deg);
    margin: 0.25rem 0 0.3rem -75px;
    overflow: hidden;
    transition: 400ms ease-in-out scale, filter;
    scale: 1;
    min-width: 0px;
    filter: blur(0px);
  }
  .project_block .projects .project .img_box:hover {
    transition: 400ms ease-in-out;
    scale: 1;
  }
  .project_block .projects .project .img_box:hover a {
    transition: none;
    visibility: visible;
    scale: 1;
    opacity: 1;
    margin-left: 20%;
    margin-top: 5%;
  }
  .project_block .projects .project .img_box:hover a button {
    transition: none;
    opacity: 1;
  }
  .project_block .projects .project .img_box:hover img {
    transition: none;
    filter: blur(0px);
  }
  .project_block .projects .project .img_box img {
    position: relative;
    top: 0;
    left: 0;
    transform: skew(20deg);
    width: auto;
    height: 100%;
    width: 100%;
    -o-object-fit: cover;
       object-fit: cover;
    border-radius: inherit;
  }
  .project_block .projects .project .img_box a {
    max-height: 40px;
    min-width: 145px;
    position: absolute;
    top: 0px;
    left: 0px;
    margin-left: 20%;
    margin-top: 5%;
    width: 60%;
    height: 15%;
    z-index: 2;
    visibility: visible;
    opacity: 1;
    transition: none;
  }
  .project_block .projects .project .img_box a button {
    opacity: 1;
    transition: none;
    transform: skew(20deg);
    filter: blur(0px);
    width: 100%;
    height: 100%;
    border: 1.5px solid var(--black);
    border-radius: 6px;
    cursor: pointer;
  }
  .project_block .projects .project .text_box {
    width: 50%;
    height: 70%;
    padding: 3%;
    overflow: hidden;
    min-width: 160px;
    overflow-y: auto;
    margin: auto 0.6rem 0.6rem auto;
    background-color: rgba(255, 255, 255, 0.75);
  }
  .contact_block {
    margin-top: 40px;
    margin-bottom: 100px;
    padding-top: 0px;
  }
  .contact_block h2 {
    margin-inline: auto;
    font-size: 40px;
    padding-bottom: 40px;
    font-family: "Rubik Mono One", sans-serif;
    text-align: center;
  }
  .contact_block p {
    margin-inline: 4%;
    margin-bottom: 0px;
    font-size: 20px;
  }
  .contact_block .contacts {
    width: 90%;
    margin-inline: auto;
    display: flex;
    flex-direction: column;
    margin-bottom: 60px;
  }
  .contact_block .contacts .contact {
    width: 100%;
    height: 100px;
    border-radius: 8px;
    transition: box-shadow 0.4s;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    min-width: 100px;
  }
  .contact_block .contacts .contact ion-icon {
    font-size: 40px;
    margin: auto;
    min-width: 40px;
  }
  .contact_block .contacts .contact p {
    margin-inline: auto;
    font-size: 18px;
    padding-top: auto;
    margin-block: auto;
    width: 100%;
    text-align: center;
  }
}/*# sourceMappingURL=home.css.map */
.progress_bar {
  display: flex;
  flex-direction: column;
  width: 70%;
  margin-inline: auto;
}
.progress_bar .options {
  display: flex;
  flex-direction: row;
}
.progress_bar .options p {
  margin-inline: 0px;
}
.progress_bar .options .to {
  margin-left: auto;
}
.progress_bar .bar {
  width: 100%;
  height: 20px;
  margin-inline: auto;
  border-radius: 8px;
  overflow: hidden;
  border: 2px solid var(--black);
}
.progress_bar .bar .bar_fill {
  width: 0;
  height: 100%;
  background: repeating-linear-gradient(45deg, var(--white), var(--white) 10px, var(--black) 10px, var(--black) 20px);
  border-right: var(--black) 2px solid;
}

.dark-theme .progress_bar .bar {
  border: 2px solid var(--white);
}
.dark-theme .progress_bar .bar .bar_fill {
  background: repeating-linear-gradient(45deg, var(--black), var(--black) 10px, var(--white) 10px, var(--white) 20px);
  border-right: var(--white) 2px solid;
}/*# sourceMappingURL=progress_bar.css.map */
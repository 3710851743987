.dark-theme {
	background-color: var(--black);
	color: white;
	.top_bar {
		// border-top: var(--white) solid 4px;
		border-bottom: 0.5px solid var(--dark-grey);
		background-color: var(--black);

		.icon_button {
			color: var(--white);
		}

		.break {
			background-color: var(--dark-grey);
		}
	}

	.sidebar {
		background-color: var(--black);
		border-right: 0.5px solid var(--dark-grey);
		a {
			border-bottom: 0.5px solid var(--dark-grey);
			ion-icon,
			i {
				color: var(--white);
			}
			button {
				background-color: var(--black);
				color: var(--white);
			}
		}
	}

	footer {
		background-color: var(--black);
		border-top: var(--white) 1px solid;
		color: rgb(189, 189, 189);
	}

	/* Handle */
	::-webkit-scrollbar-thumb {
		background: #ffffff;
	}

	.links {
		a,
		button {
			border: 1px var(--white) solid;
			color: var(--white);
			&:hover {
				box-shadow: 0px 3px 6px 0px rgba(255, 255, 255, 0.1),
					0px 1px 3px 0px rgba(255, 255, 255, 0.08);
			}
		}
	}

	.the_intro {
		.info {
			background-color: var(--black);
			border: var(--white) 2px solid;
		}
	}

	::-webkit-scrollbar {
		width: 5px;
		height: 5px;
	}
	/* Track */
	::-webkit-scrollbar-track {
		background: var(--black);
	}
	/* Handle */
	::-webkit-scrollbar-thumb {
		background: var(--white);
	}

	.project_block {
		border-top: 2px var(--white) dashed;
		.projects {
			.project {
				border: var(--white) solid 2px;
				.img_box {
					a {
						button {
							background-color: var(--black);
							border: 1.5px solid var(--white);
							color: var(--white);
						}
					}
				}
				.text_box {
					border: var(--white) solid 2px;
					background-color: rgba(0, 0, 0, 0.4); // ! other color
				}
			}
		}
	}

	.contact_block {
		border-top: 2px var(--white) dashed;
		.contacts {
			.contact {
				color: var(--white);
				border: solid 1px var(--white);
				&:hover {
					box-shadow: 0px 3px 6px 0px rgba(255, 255, 255, 0.9),
						0px 1px 3px 0px rgba(255, 255, 255, 0.92);
				}
			}
		}
	}

	.the_intro {
		.intro {
			+ :nth-child(3) {
				border-bottom: var(--white) dashed 6px;
			}
		}
		.info {
			background-color: var(--black);
			border: var(--white) 2px solid;
		}
	}

	.new_tag {
		border-top: 1px var(--white) solid;
		border-left: 2px var(--white) solid;
		border-right: 1px var(--white) solid;
		border-bottom: 2px var(--white) solid;

		color: var(--white);

		&:active {
			border-top: 2px var(--white) solid;
			border-left: 1px var(--white) solid;
			border-right: 2px var(--white) solid;
			border-bottom: 1px var(--white) solid;
		}
	}

	.links_with_box {
		.card {
			border: var(--white) dashed 2px;
		}
	}
}
